import { useMemo } from "react";
import { StyledPillFilter } from "./shared-styled-components";
import styled from "styled-components";
import { FilterChip } from "@interstate/components/Chip";

/* eslint-disable-next-line */
export interface PillFilterProps {
  disabled: boolean;
  displayName: string;
  filterCategory: string;
  isInline: boolean;
  listMode?: string;
  value: string;
  shouldIncludeCategoryName?: boolean;
  onPillClick: (category: string, value: string) => void;
}

const StyledPillFilterWrapper = styled(StyledPillFilter)`
  &.multi-deal-pill .MuiButtonBase-root.MuiChip-root {
    background-color: #c2ffbd;
    color: #298c20;
  }
`;

export function PillFilter({
  disabled,
  displayName,
  filterCategory,
  isInline,
  shouldIncludeCategoryName,
  listMode,
  value,
  onPillClick
}: PillFilterProps) {
  const pillName = useMemo(() => {
    return `${filterCategory}-${displayName}`
      .split(" ")
      .join("-")
      .toLowerCase();
  }, [displayName, filterCategory]);

  const onClick = () => {
    onPillClick(filterCategory, value);
  };

  const getPillName = () => {
    if (shouldIncludeCategoryName) {
      return `${filterCategory} ${displayName}`;
    }
    return displayName;
  };

  return (
    <StyledPillFilterWrapper
      className={
        filterCategory === "multiDealConsumerName"
          ? "multi-deal-pill"
          : undefined
      }
      key={pillName}
    >
      <FilterChip
        className={`filter-pill ${listMode}`}
        data-testid={`${pillName}-pill`}
        disabled={disabled}
        htmlId={`${
          isInline ? "inline-" : ""
        }pill-filter-${pillName}-filter-dropdown`}
        label={getPillName()}
        onClick={disabled ? undefined : onClick} // MUI isn't using an input, so jest clicks or programmatic clicks will invoke the onClick even when disabled
      />
    </StyledPillFilterWrapper>
  );
}

export default PillFilter;
