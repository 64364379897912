import { useContext, useEffect, useMemo } from "react";
import { ConversationContext, ConversationDetailsContext } from "../context";
import {
  ConversationTypeEnum,
  ConversationTypeEnum as type
} from "@coxauto-ui/communications-interfaces";
import {
  SelectInput,
  SelectInputEventValue
} from "@interstate/components/SelectInput";
import {
  conversationChannelSelectorId,
  conversationTextFromNumberSelectInputId
} from "../testIds";
import { InterstateOnChangeEvent } from "@interstate/components/InterstateEvents";
import {
  getFromChannelValueSelectInputOptions,
  getToChannelValueSelectInputOptions
} from "@coxauto-ui/communications-util";
import styled from "styled-components";
import classNames from "classnames";

export interface ChannelValueSelectorProps {
  isDisabled: boolean;
}

const StyleChannelValueSelector = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  width: 100%;

  &.side-by-side {
    > div {
      flex: 1;
      overflow: hidden;
    }
  }
`;

export function ChannelValueSelector({
  isDisabled
}: ChannelValueSelectorProps) {
  const {
    currentConversationType,
    selectedChannelValues,
    selectedFromTextingPhoneNumber,
    setSelectedChannelValues,
    setSelectedFromTextingPhoneNumber,
    recentContactEmail,
    recentContactText
  } = useContext(ConversationDetailsContext);

  const { contactDetails, dealerCommunicationDetails } =
    useContext(ConversationContext);

  useEffect(() => {
    const detailMap = new Map<type, string>([
      [type.EMAIL, recentContactEmail],
      [type.TEXT, recentContactText],
      [type.PHONE, recentContactText]
    ]);
    setSelectedChannelValues(detailMap);
  }, [recentContactEmail, recentContactText, setSelectedChannelValues]);

  useEffect(() => {
    if (
      selectedFromTextingPhoneNumber === "" &&
      dealerCommunicationDetails !== null
    ) {
      // first user phone number in the list, else dealer phone number
      const defaultNumber =
        dealerCommunicationDetails.userPhoneNumbers.length > 0 &&
        dealerCommunicationDetails.userPhoneNumbers[0].phoneNumber
          ? dealerCommunicationDetails.userPhoneNumbers[0].phoneNumber
          : dealerCommunicationDetails.dealerPhoneNumber.phoneNumber;

      setSelectedFromTextingPhoneNumber(defaultNumber);
    }
  }, [
    dealerCommunicationDetails,
    selectedFromTextingPhoneNumber,
    setSelectedFromTextingPhoneNumber
  ]);

  const toChannelOptions = useMemo(
    () =>
      getToChannelValueSelectInputOptions(
        contactDetails,
        currentConversationType || ConversationTypeEnum.NONE
      ),
    [contactDetails, currentConversationType]
  );

  const fromChannelOptions = useMemo(
    () => getFromChannelValueSelectInputOptions(dealerCommunicationDetails),
    [dealerCommunicationDetails]
  );

  const onChannelSelection = (
    e: InterstateOnChangeEvent<SelectInputEventValue>
  ) => {
    if (!currentConversationType) return;
    const value = e.target.value?.toString();
    if (value === undefined) return;
    const item = selectedChannelValues?.get(currentConversationType);

    if (item && item !== value) {
      const newMap = new Map(selectedChannelValues);

      newMap.set(currentConversationType, value);
      setSelectedChannelValues(newMap);
    }
  };

  const onFromTextingPhoneNumberSelection = (
    e: InterstateOnChangeEvent<SelectInputEventValue>
  ) => {
    if (!currentConversationType) return;

    setSelectedFromTextingPhoneNumber(e.target.value || "");
  };

  return (
    <StyleChannelValueSelector
      className={classNames({
        "side-by-side": currentConversationType === type.TEXT
      })}
    >
      {currentConversationType === type.TEXT && (
        <SelectInput
          displayLabel
          data-testid={conversationTextFromNumberSelectInputId}
          disabled={isDisabled}
          displayDeselectOption={false}
          htmlId="conversation-text-from-number-select-input"
          label="Sending Phone"
          name="ConversationTextFromNumberSelectInput"
          options={fromChannelOptions}
          value={selectedFromTextingPhoneNumber}
          onChange={onFromTextingPhoneNumberSelection}
        />
      )}
      <SelectInput
        data-testid={conversationChannelSelectorId}
        disabled={isDisabled}
        displayDeselectOption={false}
        displayLabel={
          currentConversationType === type.PHONE ||
          currentConversationType === type.TEXT
        }
        htmlId="conversation-channel-pane-dropdown"
        label={
          currentConversationType === type.TEXT
            ? "Customer Phone"
            : "Choose Phone Number"
        }
        name="ConversationChannelValueDropdown"
        options={toChannelOptions}
        value={selectedChannelValues?.get(currentConversationType || type.NONE)}
        onChange={onChannelSelection}
      />
    </StyleChannelValueSelector>
  );
}

export default ChannelValueSelector;
