import classnames from "classnames";
import { StyledProgressCheckerStep } from "./progress-checker-step-styles";

export interface ProgressCheckerProps {
  color: string;
  icon: React.ReactNode;
  id: string;
  label: string;
  labelColor?: string;
  nextColor: string;
  position: "first" | "middle" | "last";
  subtext?: string[];
}

export function ProgressCheckerStep({
  color,
  icon,
  id,
  label,
  nextColor,
  labelColor,
  position,
  subtext
}: ProgressCheckerProps) {
  return (
    <StyledProgressCheckerStep
      className={classnames("stepper-checker-step", position)}
      style={{ color }}
    >
      <div className="stepper-visual-indicator">
        {position !== "first" && (
          <hr
            className="stepper-horizontal-left"
            data-testid={`${id}-horizontal-left`}
          />
        )}
        {position !== "last" && (
          <hr
            className="stepper-horizontal-right"
            data-testid={`${id}-horizontal-right`}
            style={{ borderColor: nextColor }}
          />
        )}
        <div
          className="stepper-icon-wrapper"
          data-testid={`${id}-stepper-icon-wrapper`}
          id={`${id}-stepper-icon-wrapper`}
        >
          <span className="stepper-icon">{icon}</span>
        </div>
      </div>
      <div className="stepper-text-wrapper">
        <div
          className="stepper-label"
          data-testid={`${id}-stepper-label`}
          id={`${id}-stepper-label`}
          style={{ color: labelColor || color }}
        >
          {label}
        </div>
        {subtext &&
          subtext.length > 0 &&
          subtext.map((x, index) => (
            <div
              className="stepper-subtext"
              data-testid={`${id}-stepper-subtext-${index}`}
              id={`${id}-stepper-subtext-${index}`}
              key={x}
            >
              {x}
            </div>
          ))}
      </div>
    </StyledProgressCheckerStep>
  );
}

export default ProgressCheckerStep;
