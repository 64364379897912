import { EventResultEnum } from "@coxauto-ui/communications-interfaces";
import { InformationCircleIcon } from "@interstate/components/Icons";
import styled from "styled-components";
import { messageDeliveryFailed, messageDeliveryFailedIcon } from "../testIds";

const StyledContainer = styled.div`
  display: block;
`;

const StyledMessageEventType = styled.div`
  display: inline-block;
`;

const StyledIconInfoOutline = styled(InformationCircleIcon)`
  color: #d41e27;
  display: inline-block;
  vertical-align: middle;
`;

const StyledMessageEventTypeError = styled.span`
  color: #d41e27;
  font-size: 12px;
  margin-left: 0.2rem;
  display: inline-block;
  vertical-align: middle;
`;

export interface MessageEventResultProps {
  eventResult: EventResultEnum;
}

export function MessageEventResult(props: MessageEventResultProps) {
  const { eventResult } = props;

  if (eventResult === EventResultEnum.FAILED) {
    return (
      <StyledContainer>
        <StyledMessageEventType>
          <StyledIconInfoOutline data-testid={messageDeliveryFailedIcon} />
          <StyledMessageEventTypeError data-testid={messageDeliveryFailed}>
            Message delivery failed
          </StyledMessageEventTypeError>
        </StyledMessageEventType>
      </StyledContainer>
    );
  }

  return null;
}

export default MessageEventResult;
