// eslint-disable-next-line no-restricted-imports
import styled from "styled-components";
import {
  CheckBoxList,
  CheckboxListOptions
} from "@interstate/components/CheckBoxList";
import { CcxmReusableCollapsible } from "@coxauto-ui/ccxm/reusable/collapsible";

/* eslint-disable-next-line */
export interface CcxmReusableCollapsibleCheckboxListProps {
  className?: string;
  errorMessage?: string;
  expandedByDefault?: boolean;
  hasError?: boolean;
  htmlId: string;
  inline?: boolean;
  label: string;
  layout?: "vertical" | "horizontal";
  name: string;
  /**
   * onChange callback. Called with `cb(cxEvent, isValid, domEvent)`.
   * Open the console to view returned data from fired onChange event.
   * - cxEvent: Returns component specific data whose shape is `{target: {name, value}}`.
   * - isValid: Boolean that specifies if the control is in error.
   * - domEvent: The unaltered event returned from the input's interaction, if exists.
   */
  onChange: (...args: any[]) => any;
  options: CheckboxListOptions[];
  required?: boolean;
  values?: string[];
}

export const StyledCollapsibleCheckboxList = styled.div`
  .checkbox label {
    font-weight: 500;
  }

  // Interstate defect displays an erroneous svg inside an unselected disabled checkbox
  .MuiFormControlLabel-root .MuiCheckbox-root:not(.Mui-checked) svg {
    display: none;
  }
`;

export function CcxmReusableCollapsibleCheckboxList({
  expandedByDefault = true,
  label,
  htmlId,
  className,
  errorMessage,
  hasError,
  inline,
  layout,
  name,
  onChange,
  options,
  required,
  values
}: CcxmReusableCollapsibleCheckboxListProps) {
  return (
    <StyledCollapsibleCheckboxList data-testid={htmlId} id={htmlId}>
      <CcxmReusableCollapsible
        expandedByDefault={expandedByDefault}
        htmlId={htmlId}
        label={label}
      >
        <div className={className}>
          <CheckBoxList
            displayLabel={false}
            errorMessage={errorMessage}
            hasError={hasError}
            htmlId={htmlId}
            inline={inline}
            layout={layout}
            name={name}
            options={options}
            required={required}
            spacing={"sm"}
            values={values}
            onChange={onChange}
          />
        </div>
      </CcxmReusableCollapsible>
    </StyledCollapsibleCheckboxList>
  );
}

export default CcxmReusableCollapsibleCheckboxList;
