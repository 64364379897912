import {
  MatrixFilters,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import { useMemo, useState } from "react";
import styled from "styled-components";

import { InterstateOnChangeCallback } from "@interstate/components/InterstateEvents";
import {
  SearchInput,
  SearchInputChangeEventValue
} from "@interstate/components/SearchInput";

/* eslint-disable-next-line */
export interface CcxmInsightsFiltersSearchFilterProps {
  className?: string;
  optionFilters: OptionFilters;
  textFilters: TextFilters;
  matrixFilters?: MatrixFilters;
  onChange: (
    optionFiltersToApply: OptionFilters,
    textFiltersToApply: TextFilters,
    matrixFiltersToApply?: MatrixFilters
  ) => void;
}

const StyledCcxmInsightsFiltersSearchFilter = styled.div`
  color: #cacfd9;
  display: flex;
  align-items: center;
  gap: 12px;
  height: 32px;
  min-width: 300px;

  #ccxm-insights-filters-search-filter-text-input {
  }
  label.MuiInputLabel-shrink {
    display: none;
  }
`;

const baseId = "ccxm-insights-filters-search-filter";

export function CcxmInsightsFiltersSearchFilter({
  className,
  optionFilters,
  textFilters,
  matrixFilters,
  onChange
}: CcxmInsightsFiltersSearchFilterProps) {
  const [searchText, setSearchText] = useState("");

  const onSearch = () => {
    if (inputIsValid) {
      const textFiltersToApply: TextFilters = JSON.parse(
        JSON.stringify(textFilters)
      );
      textFiltersToApply["consumerName"].textInputValue = searchText;

      onChange(optionFilters, textFiltersToApply, matrixFilters);
      setSearchText("");
    }
  };

  const inputIsValid = useMemo(() => {
    return searchText.length > 2;
  }, [searchText]);

  const onTextInputChange: InterstateOnChangeCallback<
    SearchInputChangeEventValue
  > = e => {
    if (e.target.value) {
      setSearchText(e.target.value as string);
    }
  };

  return (
    <StyledCcxmInsightsFiltersSearchFilter className={className}>
      <SearchInput
        data-testid={`${baseId}-text-input`}
        disableSearch={!inputIsValid}
        id={`${baseId}-text-input`}
        inputValue={searchText}
        name="retail-three-sixty-search-input"
        placeholder="Type customer name to begin search"
        size="small"
        // eslint-disable-next-line react/style-prop-object
        style={"primary"}
        onInputChange={onTextInputChange}
        onSearchClick={onSearch}
      />
    </StyledCcxmInsightsFiltersSearchFilter>
  );
}

export default CcxmInsightsFiltersSearchFilter;
