import {
  PayloadAction,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice
} from "@reduxjs/toolkit";
import {
  DealInsightsRecordsEntity,
  DealInsightsRecordsState,
  DealInsightsRowsRequest,
  DealInsightsRowsResponse,
  LoadingStatus
} from "@coxauto-ui/ccxm/interfaces";
import { getDealInsightsRows } from "@coxauto-ui/ccxm/api";

export const DEAL_INSIGHTS_RECORDS_FEATURE_KEY = "dealInsightsRecords";

export const dealInsightsRecordsAdapter =
  createEntityAdapter<DealInsightsRecordsEntity>({
    selectId: dealInsightsRecordsEntity => dealInsightsRecordsEntity.dealId
  });

export const fetchDealInsightsRecords = createAsyncThunk(
  "dealInsightsRecords/fetchStatus",
  async (request: DealInsightsRowsRequest) => {
    return await getDealInsightsRows(request);
  }
);

export const initialDealInsightsRecordsState: DealInsightsRecordsState =
  dealInsightsRecordsAdapter.getInitialState({
    error: "",
    filterSequence: [],
    loadingStatus: LoadingStatus.notLoaded,
    paging: {
      pageCount: 0,
      pageNumber: 0,
      pageSize: 20,
      totalItems: 0
    },
    matrixFilters: {},
    optionFilters: {},
    textFilters: {}
  });

export const dealInsightsRecordsSlice = createSlice({
  name: DEAL_INSIGHTS_RECORDS_FEATURE_KEY,
  initialState: initialDealInsightsRecordsState,
  reducers: {
    add: dealInsightsRecordsAdapter.addOne,
    remove: dealInsightsRecordsAdapter.removeOne
  },
  extraReducers: builder => {
    builder
      .addCase(
        fetchDealInsightsRecords.pending,
        (state: DealInsightsRecordsState) => {
          state.loadingStatus = LoadingStatus.loading;
        }
      )
      .addCase(
        fetchDealInsightsRecords.fulfilled,
        (
          state: DealInsightsRecordsState,
          action: PayloadAction<DealInsightsRowsResponse>
        ) => {
          if (action.payload.paging.pageNumber === 1) {
            dealInsightsRecordsAdapter.setAll(state, action.payload.deals);
          } else {
            dealInsightsRecordsAdapter.addMany(state, action.payload.deals);
          }
          state.paging = action.payload.paging;
          state.filterSequence = action.payload.filterSequence;
          state.optionFilters = action.payload.optionFilters;
          state.matrixFilters = action.payload.matrixFilters;
          state.textFilters = action.payload.textFilters;
          state.loadingStatus = LoadingStatus.loaded;
        }
      )
      .addCase(
        fetchDealInsightsRecords.rejected,
        (state: DealInsightsRecordsState, action) => {
          state.loadingStatus = LoadingStatus.error;
          state.error = action.error.message || "";
        }
      );
  }
});

export const dealInsightsRecordsReducer = dealInsightsRecordsSlice.reducer;

export const dealInsightsRecordsActions = dealInsightsRecordsSlice.actions;

const { selectAll, selectEntities } = dealInsightsRecordsAdapter.getSelectors();

export const getDealInsightsRecordsState = (
  rootState: any
): DealInsightsRecordsState => rootState[DEAL_INSIGHTS_RECORDS_FEATURE_KEY];

export const selectAllDealInsightsRecords = createSelector(
  getDealInsightsRecordsState,
  selectAll
);

export const selectDealInsightsRecordsEntities = createSelector(
  getDealInsightsRecordsState,
  selectEntities
);
