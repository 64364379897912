import styled from "styled-components";
import { TabProps, Tabs } from "@interstate/components/Tabs";
import { TabsProps } from "@interstate/components/Tabs/Types/tabsTypes";

type Tab = Omit<TabProps, "component">;
export interface CcxmReusableBasicTabsProps
  extends Omit<TabsProps, "tabs" | "onActivate"> {
  tabs: Tab[];
  htmlId?: string;
  disabled?: boolean;
  onActivate: (tabId: number, tab: Tab) => void;
}

const StyledTabsWrapper = styled.div`
  && .MuiTabs-root {
    border-bottom: none;
    min-height: 40px;
    padding-left: 0;
    padding-right: 0;
    .MuiTabs-flexContainer {
      gap: 0;

      button {
        border: 1px solid var(--border-muted, #b2b2b2);
        flex: 1;
        padding: 12px;
        height: 40px;
        min-height: 40px;
      }

      button + button {
        border-left: none;
      }

      button:first-child {
        border-left: 1px solid var(--border-muted, #b2b2b2);
        border-radius: 4px 0 0 4px;
      }

      button:last-child {
        border-right: 1px solid var(--border-muted, #b2b2b2);
        border-radius: 0 4px 4px 0;
      }
    }
  }
  .MuiBox-root {
    display: none;
  }
`;

export function CcxmReusableBasicTabs({
  tabs,
  htmlId,
  disabled = false,
  onActivate,
  ...rest
}: CcxmReusableBasicTabsProps) {
  const tabsWithoutComponent = tabs.map(tab => ({
    ...tab,
    component: <></>,
    disabled,
    "data-testid": `${htmlId}-basic-tab-${tab.id?.toLowerCase()}`
  }));

  const handleActivate = (tabId: number) => {
    const tab = tabsWithoutComponent[tabId];
    onActivate(tabId, tab);
  };

  return (
    <StyledTabsWrapper
      className={`ccxm-reusable-basic-tabs`}
      data-testid="basic-tabs-wrapper"
    >
      <Tabs
        {...rest}
        htmlId={htmlId}
        tabs={tabsWithoutComponent}
        onActivate={handleActivate}
      />
    </StyledTabsWrapper>
  );
}

export default CcxmReusableBasicTabs;
