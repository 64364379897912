/* eslint-disable no-restricted-imports */
import React, { useCallback, useMemo, useState } from "react";

import {
  FilterSequence,
  FilterSequenceFilterType,
  MatrixFilters,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import PillFilter from "./ccxm-insights-pill-filter";
import { StyledClearFilter } from "./shared-styled-components";
import { ArrowUturnLeftIcon } from "@interstate/components/Icons/ArrowUturnLeftIcon";
import { Button } from "@interstate/components/Button";
import { LinkChip } from "@interstate/components/Chip";

/* eslint-disable-next-line */
export interface CcxmInsightsPillFiltersProps {
  clearButtonDisplay?: "inline" | "separate";
  filterSequence: FilterSequence[];
  isInline?: boolean;
  listMode?: string;
  loading?: boolean;
  optionFilters: OptionFilters;
  matrixFilters?: MatrixFilters;
  textFilters: TextFilters;
  onChange: (
    optionFilters: OptionFilters,
    textFilters: TextFilters,
    matrixFilters?: MatrixFilters
  ) => void;
}

const numberOfPillsToDisplay = 4;

export function CcxmInsightsPillFilters({
  clearButtonDisplay = "inline",
  filterSequence,
  isInline = true,
  listMode,
  matrixFilters,
  loading = false,
  optionFilters,
  textFilters,
  onChange
}: CcxmInsightsPillFiltersProps) {
  const [showMore, setShowMore] = useState(false);

  const onClearAllFiltersClick = () => {
    const textFiltersToApply: TextFilters = JSON.parse(
      JSON.stringify(textFilters)
    );
    const optionFiltersToApply: OptionFilters = JSON.parse(
      JSON.stringify(optionFilters)
    );

    const matrixFiltersToApply: MatrixFilters = matrixFilters
      ? JSON.parse(JSON.stringify(matrixFilters))
      : {};

    for (let i = 0; i < filterSequence.length; i++) {
      const { categoryType, filterType } = filterSequence[i];
      if (filterType === FilterSequenceFilterType.textFilter) {
        textFiltersToApply[categoryType].textInputValue = "";
      }
      if (filterType === FilterSequenceFilterType.optionFilter) {
        optionFiltersToApply[categoryType].selectedValues = [];
      }

      if (
        filterType === FilterSequenceFilterType.matrixFilter &&
        matrixFilters
      ) {
        matrixFiltersToApply[categoryType].options = matrixFilters[
          categoryType
        ].options.map(option => {
          return {
            ...option,
            selectedValues: []
          };
        });
      }
    }

    setShowMore(false);
    onChange(optionFiltersToApply, textFiltersToApply, matrixFiltersToApply);
  };

  const onOptionsListPillFilterClick = useCallback(
    (categoryType: string, value: string) => {
      const optionFiltersToApply: OptionFilters = JSON.parse(
        JSON.stringify(optionFilters)
      );
      const selectedValues = optionFilters[categoryType].selectedValues.filter(
        x => x !== value
      );

      optionFiltersToApply[categoryType].selectedValues = selectedValues;

      onChange(optionFiltersToApply, textFilters, matrixFilters);
    },
    [optionFilters, textFilters, onChange, matrixFilters]
  );

  const onMatrixListPillFilterClick = useCallback(
    (categoryType: string) => (matrixCategory: string, value: string) => {
      const matrixFiltersToApply: MatrixFilters = JSON.parse(
        JSON.stringify(matrixFilters)
      );
      if (matrixFilters) {
        matrixFiltersToApply[categoryType].options = matrixFilters[
          categoryType
        ]?.options.map(option => {
          if (option.category === matrixCategory) {
            return {
              ...option,
              selectedValues: []
            };
          }
          return option;
        });

        onChange(optionFilters, textFilters, matrixFiltersToApply);
      }
    },
    [optionFilters, textFilters, matrixFilters, onChange]
  );

  const onShowMoreFiltersPillClick = () => {
    setShowMore(b => !b);
  };

  const onTextInputPillFilterClick = useCallback(
    (categoryType: string) => {
      const textFiltersToApply: TextFilters = JSON.parse(
        JSON.stringify(textFilters)
      );
      textFiltersToApply[categoryType].textInputValue = "";

      onChange(optionFilters, textFiltersToApply, matrixFilters);
    },
    [optionFilters, textFilters, onChange, matrixFilters]
  );

  const pills = useMemo(() => {
    // if we continue to expand filter types, we should create a mapper that returns a list of React pills as a util (e.g. EzMapper)
    return filterSequence.reduce<React.ReactElement[]>(
      (accum, { filterType, categoryType }) => {
        if (filterType === FilterSequenceFilterType.optionFilter) {
          const { options, selectedValues } = optionFilters[categoryType];
          for (let i = 0; i < options.length; i++) {
            if (selectedValues.includes(options[i].value)) {
              const Component = (
                <PillFilter
                  disabled={loading}
                  displayName={options[i].label}
                  filterCategory={categoryType}
                  isInline={isInline}
                  key={`${categoryType}-${options[i].value}`}
                  listMode={listMode}
                  value={options[i].value}
                  onPillClick={onOptionsListPillFilterClick}
                />
              );
              // eslint-disable-next-line no-param-reassign
              accum = [...accum, Component];
            }
          }
        }
        if (
          matrixFilters &&
          matrixFilters[categoryType] &&
          filterType === FilterSequenceFilterType.matrixFilter
        ) {
          const { options: matrixOptions } = matrixFilters[categoryType];
          for (let i = 0; i < matrixOptions.length; i++) {
            const { options, selectedValues, category } = matrixOptions[i];
            for (let j = 0; j < options.length; j++) {
              if (selectedValues.includes(options[j].value)) {
                const { label, value } = options[j];
                const Component = (
                  <PillFilter
                    disabled={loading}
                    displayName={label}
                    filterCategory={category}
                    isInline={isInline}
                    key={`${category}-${value}`}
                    listMode={listMode}
                    shouldIncludeCategoryName={true}
                    value={value}
                    onPillClick={onMatrixListPillFilterClick(categoryType)}
                  />
                );
                // eslint-disable-next-line no-param-reassign
                accum = [...accum, Component];
              }
            }
          }
        }
        if (
          filterType === FilterSequenceFilterType.textFilter &&
          textFilters[categoryType]?.textInputValue.length > 0
        ) {
          const { textInputValue } = textFilters[categoryType];
          const Component = (
            <PillFilter
              disabled={loading}
              displayName={textInputValue}
              filterCategory={categoryType}
              isInline={isInline}
              key={`${categoryType}-${textInputValue}`}
              listMode={listMode}
              value={textInputValue}
              onPillClick={onTextInputPillFilterClick}
            />
          );
          // eslint-disable-next-line no-param-reassign
          accum = [...accum, Component];
        }
        return accum;
      },
      []
    );
  }, [
    filterSequence,
    isInline,
    listMode,
    loading,
    optionFilters,
    textFilters,
    matrixFilters,
    onOptionsListPillFilterClick,
    onTextInputPillFilterClick
  ]);

  return (
    <>
      {pills.length > 0 && showMore
        ? pills
        : pills.slice(0, numberOfPillsToDisplay)}
      {pills.length > numberOfPillsToDisplay && (
        <LinkChip
          className="filter-pill"
          data-pillcount={
            showMore ? pills.length : pills.length - numberOfPillsToDisplay
          }
          data-testid="show-more-pill"
          htmlId={`${
            isInline ? "inline-" : ""
          }pill-filter-show-more-filter-dropdown`}
          label={
            showMore
              ? "Show less"
              : `+${pills.length - numberOfPillsToDisplay} more`
          }
          onClick={onShowMoreFiltersPillClick}
        />
      )}
      {pills.length > 0 && (
        <StyledClearFilter className="inline-clear-button">
          <Button
            buttonStyle="tertiary"
            data-testid={
              clearButtonDisplay === "inline"
                ? "drawer-filter-clear-button"
                : "filter-clear-button"
            }
            disabled={loading || pills.length === 0}
            htmlId={`${isInline ? "inline-" : ""}pill-filters-clear-button`}
            size="small"
            startIcon={<ArrowUturnLeftIcon />}
            onClick={onClearAllFiltersClick}
          >
            Clear
          </Button>
        </StyledClearFilter>
      )}
    </>
  );
}

export default CcxmInsightsPillFilters;
