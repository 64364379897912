import styled from "styled-components";
import {
  FilterSequence,
  FilterSequenceFilterType,
  MatrixFilters,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import React, { ReactNode, useMemo } from "react";
import { CcxmInsightsCheckboxFilter } from "@coxauto-ui/ccxm-insights-filters-checkbox-filters";
import { CcxmInsightsFiltersTabsFilters } from "@coxauto-ui/ccxm/insights/filters/tabs-filters";

/* eslint-disable-next-line */
export interface CcxmInsightsFiltersSlidingDrawerFiltersProps {
  disabled: boolean;
  filterSequence: FilterSequence[];
  optionFilters: OptionFilters;
  matrixFilters?: MatrixFilters;
  textFilters: TextFilters;
  onChange: (
    optionFiltersToApply: OptionFilters,
    textFiltersToApply: TextFilters,
    matrixFiltersToApply?: MatrixFilters
  ) => void;
}

const StyledCcxmInsightsFiltersSlidingDrawerFilters = styled.div``;

export function CcxmInsightsFiltersSlidingDrawerFilters({
  textFilters,
  optionFilters,
  matrixFilters,
  filterSequence,
  disabled,
  onChange
}: CcxmInsightsFiltersSlidingDrawerFiltersProps) {
  const Filters = useMemo(() => {
    return filterSequence.reduce<ReactNode[]>(
      (list, { categoryType, filterType }) => {
        let component = null;
        if (filterType === FilterSequenceFilterType.optionFilter) {
          component = (
            <CcxmInsightsCheckboxFilter
              categoryType={categoryType}
              disabled={disabled}
              key={categoryType}
              matrixFilters={matrixFilters}
              optionFilters={optionFilters}
              textFilters={textFilters}
              onChange={onChange}
            />
          );
        } else if (
          filterType === FilterSequenceFilterType.matrixFilter &&
          matrixFilters
        ) {
          component = (
            <CcxmInsightsFiltersTabsFilters
              categoryType={categoryType}
              disabled={disabled}
              key={categoryType}
              matrixFilters={matrixFilters}
              optionFilters={optionFilters}
              textFilters={textFilters}
              onChange={onChange}
            />
          );
        }
        // eslint-disable-next-line no-param-reassign
        list = [...list, component];
        return list;
      },
      []
    );
  }, [
    filterSequence,
    optionFilters,
    matrixFilters,
    textFilters,
    disabled,
    onChange
  ]);

  return (
    <StyledCcxmInsightsFiltersSlidingDrawerFilters>
      {Filters}
    </StyledCcxmInsightsFiltersSlidingDrawerFilters>
  );
}

export default CcxmInsightsFiltersSlidingDrawerFilters;
