import { DealStages, DealStagesStep } from "@coxauto-ui/ccxm/interfaces";
import { CheckCircleIcon } from "@interstate/components/Icons/CheckCircleIcon";
import styled from "styled-components";

export enum AllowedField {
  status = "status",
  date = "date"
}

const noCompleteColor = "#B2B2B2";

const completeColor = "#3c7b29";

const noCompleteLabelColor = "#6D6D6D";

const CircleIcon = styled.div`
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border: 1.5px solid ${noCompleteColor};
  border-radius: 50%;
`;
const noCompleteStage = {
  color: noCompleteColor,
  labelColor: noCompleteLabelColor,
  icon: <CircleIcon />
};

const completeStage = {
  color: completeColor,
  icon: <CheckCircleIcon />
};

interface BaseStep {
  key: Exclude<keyof DealStages, "status">;
  label: string;
}

const baseSteps: BaseStep[] = [
  {
    key: "negotiation",
    label: "Negotiation"
  },
  {
    key: "checkout",
    label: "Checkout"
  },
  {
    key: "contracting",
    label: "Contracting"
  },
  {
    key: "delivery",
    label: "Delivery"
  }
];

export function mapDealChecker(
  dealStages: DealStages,
  allowedFields: AllowedField[]
) {
  const getSubtext = (stage: DealStagesStep) => {
    const subtext = [];
    if (allowedFields.includes(AllowedField.status)) {
      subtext.push(stage.state);
    }
    if (allowedFields.includes(AllowedField.date) && stage.completionDateUtc) {
      subtext.push(new Date(stage.completionDateUtc).toLocaleDateString());
    }
    return subtext;
  };

  return baseSteps.map(({ key, label }, index) => {
    const stageKey = key;
    const nextStageKey = baseSteps[index + 1]?.key;
    const stage = dealStages[stageKey];
    const nextStage = dealStages[nextStageKey];
    const nextStageIsComplete = nextStage?.isComplete;
    const nextColor = nextStageIsComplete ? completeColor : noCompleteColor;
    return {
      label,
      nextColor,
      subtext: getSubtext(stage),
      ...(stage.isComplete ? completeStage : noCompleteStage)
    };
  });
}
