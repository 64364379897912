import { CcxmAmpCards } from "@coxauto-ui/ccxm/amp/cards";
import { CcxmInsightsList } from "@coxauto-ui/ccxm/insights/list";
import {
  fetchOpportunityInsightsRecords,
  getOpportunityInsightsRecordsState,
  getUserProfileState,
  selectAllOpportunityInsightsRecords,
  useAppDispatch
} from "@coxauto-ui/ccxm/store";
import { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { getIsAmpStandalone } from "@coxauto-ui/ccxm/api";
import { deriveEnvironmentName } from "@coxauto-ui/ccxm/util/derive-environment";
import {
  LoadingStatus,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import useInsightsListMode from "@coxauto-ui/ccxm/util/use-insights-list-mode";
import { useRoutingParams } from "@coxauto-ui/ccxm-routing";

export function CcxmAmpList() {
  const [displayVinButton, setDisplayVinButton] = useState(false);
  const dispatch = useAppDispatch();
  const listMode = useInsightsListMode();
  const { customerId } = useRoutingParams();
  const { loadingStatus } = useSelector(
    getOpportunityInsightsRecordsState,
    shallowEqual
  );

  const { dealerGroupId, dealerGroupName, bridgeUserId, viewAsUsername } =
    useSelector(getUserProfileState, shallowEqual);

  const {
    error,
    filterSequence,
    paging,
    optionFilters,
    textFilters,
    visibleColumns
  } = useSelector(getOpportunityInsightsRecordsState, shallowEqual);

  const opportunityRecords = useSelector(
    selectAllOpportunityInsightsRecords,
    shallowEqual
  );

  useEffect(() => {
    const savedFilters = localStorage.getItem(
      `filters_amp_${bridgeUserId}_${viewAsUsername}`
    );
    if (savedFilters) {
      const { optionFilters, textFilters } = JSON.parse(savedFilters);
      dispatch(
        fetchOpportunityInsightsRecords({
          optionFilters,
          page: 1,
          pageSize: paging.pageSize,
          textFilters
        })
      );
    }
  }, [bridgeUserId, dispatch, paging.pageSize, viewAsUsername]);

  useEffect(() => {
    if (customerId !== undefined && customerId !== "" && customerId !== null) {
      (async () => {
        const showVin = await getIsAmpStandalone(
          customerId || "",
          deriveEnvironmentName()
        );
        setDisplayVinButton(showVin.data?.isAmpStandalone === false);
      })();
    }
  }, [customerId]);

  const onFilterChange = useCallback(
    (optionFiltersToApply: OptionFilters, textFiltersToApply: TextFilters) => {
      dispatch(
        fetchOpportunityInsightsRecords({
          optionFilters: optionFiltersToApply,
          page: 1,
          pageSize: paging.pageSize,
          textFilters: textFiltersToApply
        })
      )
        .unwrap()
        .then(() => {
          localStorage.setItem(
            `filters_amp_${bridgeUserId}_${viewAsUsername}`,
            JSON.stringify({
              optionFilters: optionFiltersToApply,
              textFilters: textFiltersToApply
            })
          );
        })
        .catch(() => {
          // do nothing
        });
    },
    [dispatch, paging.pageSize, bridgeUserId, viewAsUsername]
  );

  const onLoadMore = useCallback(() => {
    dispatch(
      fetchOpportunityInsightsRecords({
        optionFilters,
        page: paging.pageNumber + 1,
        pageSize: paging.pageSize,
        textFilters
      })
    );
  }, [
    dispatch,
    optionFilters,
    paging.pageNumber,
    paging.pageSize,
    textFilters
  ]);

  return (
    <CcxmInsightsList
      dealerGroupId={dealerGroupId}
      dealerGroupName={dealerGroupName}
      displayVinButton={displayVinButton}
      error={error || undefined}
      filterSequence={filterSequence}
      loading={loadingStatus === LoadingStatus.loading}
      optionFilters={optionFilters}
      pageTitle="Opportunities"
      paging={paging}
      showFilters={true}
      textFilters={textFilters}
      onFilterChange={onFilterChange}
      onLoadMore={onLoadMore}
    >
      <CcxmAmpCards
        filterSequence={filterSequence}
        listMode={listMode}
        loadingStatus={loadingStatus}
        opportunityRecords={opportunityRecords}
        optionFilters={optionFilters}
        paging={paging}
        textFilters={textFilters}
        visibleColumns={visibleColumns}
      />
    </CcxmInsightsList>
  );
}

export default CcxmAmpList;
