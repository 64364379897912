import { Environment } from "@coxauto-ui/communications-interfaces";
import { fetchWrapper } from "@coxauto-ui/core-http";
import { getBaseUrl, getHeader } from "../config";
import {
  GetDealerCommunicationDetailsByConsumerIdRequest,
  GetDealerCommunicationDetailsByConsumerIdResponse
} from "./interfaces/get-dealer-communication-details-by-consumer-id";

export async function getDealerCommunicationDetailsByConsumerId(
  data: GetDealerCommunicationDetailsByConsumerIdRequest,
  env: Environment
): Promise<GetDealerCommunicationDetailsByConsumerIdResponse> {
  const url = `${getBaseUrl(
    env
  )}cxm/dealer-communication-details-by-consumerId`;
  const header = await getHeader();

  const response =
    await fetchWrapper.getAsync<GetDealerCommunicationDetailsByConsumerIdResponse>(
      `${url}?consumerId=${data.consumerId}&ConsumerRecordSystem=${data.consumerRecordSystem}`,
      header
    );

  if (!response || !response.data || response.hasError) {
    throw new Error(response.error?.message || "An unexpected error occurred");
  }

  return response.data;
}
