import styled from "styled-components";
import {
  messageItemContentBottomAttachmentIconId,
  messageItemContentBottomId,
  messageItemContentBottomNumberOfAttachmentsId
} from "../testIds";
import { PaperClipIcon } from "@interstate/components/Icons";

const StyledMessageItemContentBottom = styled.span`
  border-top: 1px solid #b2b2b2;
  display: flex;
  margin-top: 20px;
  margin-bottom: 7px;
  text-align: left;
  color: #005ba8;
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 15px;
`;

const StyledMessageItemNumberOfAttachments = styled.div`
  display: flex;
  align-items: center;
`;

const StyledPaperClipIconContainer = styled.div`
  margin-right: 6px;
  display: flex;
`;

const StyledReadMore = styled.a`
  color: #005ba8;
  margin-left: auto;
  cursor: pointer;
  text-decoration: none;
`;

export interface MessageItemContentBottomProps {
  contentId: number;
  numberOfAttachments: number;
}

export function MessageItemContentBottom({
  contentId,
  numberOfAttachments
}: MessageItemContentBottomProps) {
  return (
    <StyledMessageItemContentBottom
      data-testid={messageItemContentBottomId + contentId}
    >
      {numberOfAttachments > 0 && (
        <StyledMessageItemNumberOfAttachments
          data-testid={
            messageItemContentBottomNumberOfAttachmentsId + contentId
          }
        >
          <StyledPaperClipIconContainer>
            <PaperClipIcon
              data-testid={messageItemContentBottomAttachmentIconId}
            />
          </StyledPaperClipIconContainer>
          {`${numberOfAttachments} Attachment(s)`}
        </StyledMessageItemNumberOfAttachments>
      )}
      <StyledReadMore>Read More</StyledReadMore>
    </StyledMessageItemContentBottom>
  );
}

export default MessageItemContentBottom;
