import { useContext } from "react";
import styled from "styled-components";
import { ConversationContext, ConversationDetailsContext } from "./context";
import { customerNameHeaderTestId } from "./testIds";
import { useInterstateTheme } from "@interstate/components/InterstateThemeProvider";

const StyledConversationPaneHeader = styled.div`
  border-bottom: 1px solid #e3e3e3;
  padding: 0.5em;
  button {
    font-weight: 700;
    font-size: 1.5em;
    color: #000;
    padding: 5px;
  }
`;

const StyledBreadCrumbArea = styled.div``;

export function ConversationPaneHeader() {
  const { customerName, showCustomerInfo, title } =
    useContext(ConversationContext);
  const { selectedConversationItem } = useContext(ConversationDetailsContext);
  const { tokens } = useInterstateTheme();

  if (!showCustomerInfo || selectedConversationItem) return null;

  return (
    <StyledConversationPaneHeader>
      <StyledBreadCrumbArea data-testid={customerNameHeaderTestId}>
        {showCustomerInfo ? title || customerName : ""}
      </StyledBreadCrumbArea>
    </StyledConversationPaneHeader>
  );
}
