import { AttachmentTypeEnum } from "@coxauto-ui/communications-interfaces";
import {
  DocumentIcon,
  PhotoIcon,
  SpeakerWaveIcon,
  VideoCameraIcon
} from "@interstate/components/Icons";
import styled from "styled-components";
import {
  messageItemAttachmentIconAudio,
  messageItemAttachmentIconFile,
  messageItemAttachmentIconImage,
  messageItemAttachmentIconVideo
} from "../testIds";
export interface AttachmentIconProps {
  attachmentType: AttachmentTypeEnum;
}
export const StyledImageIcon = styled(PhotoIcon)`
  height: 16px;
  width: 16px;
  color: #fff;
`;

export const StyledVideoIcon = styled(VideoCameraIcon)`
  height: 16px;
  width: 16px;
  color: #fff;
`;

export const StyledAudioIcon = styled(SpeakerWaveIcon)`
  height: 16px;
  width: 16px;
  color: #fff;
`;

export const StyledFileIcon = styled(DocumentIcon)`
  height: 16px;
  width: 16px;
  color: #fff;
`;

export function AttachmentIcon({ attachmentType }: AttachmentIconProps) {
  switch (attachmentType) {
    case AttachmentTypeEnum.IMAGE:
      return <StyledImageIcon data-testid={messageItemAttachmentIconImage} />;
    case AttachmentTypeEnum.VIDEO:
      return <StyledVideoIcon data-testid={messageItemAttachmentIconVideo} />;
    case AttachmentTypeEnum.AUDIO:
      return <StyledAudioIcon data-testid={messageItemAttachmentIconAudio} />;
    default:
      return <StyledFileIcon data-testid={messageItemAttachmentIconFile} />;
  }
}

export default AttachmentIcon;
