import {
  FilterSequence,
  FilterSequenceFilterType,
  MatrixFilters,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";

const getSelectedFilterCategories = ({
  filterSequence,
  textFilters,
  optionFilters,
  matrixFilters
}: {
  filterSequence: FilterSequence[];
  textFilters: TextFilters;
  optionFilters: OptionFilters;
  matrixFilters?: MatrixFilters;
}) => {
  const selectedCategories: string[] = [];
  filterSequence.forEach(filter => {
    if (filter.filterType === FilterSequenceFilterType.textFilter) {
      if (textFilters[filter.categoryType]?.textInputValue.length > 0) {
        selectedCategories.push(filter.categoryType);
      }
    } else if (filter.filterType === FilterSequenceFilterType.optionFilter) {
      if (optionFilters[filter.categoryType]?.selectedValues.length > 0) {
        selectedCategories.push(filter.categoryType);
      }
    } else if (filter.filterType === FilterSequenceFilterType.matrixFilter) {
      if (matrixFilters) {
        const selectedMatrixValues = matrixFilters[
          filter.categoryType
        ]?.options.flatMap(option => {
          if (option.selectedValues.length > 0) {
            return [option.category];
          }
          return [];
        });
        selectedCategories.push(...selectedMatrixValues);
      }
    }
  });

  return selectedCategories.join(", ");
};

export default getSelectedFilterCategories;
