import {
  getCommunications,
  getErrorMessage,
  isResponseError
} from "@coxauto-ui/communications-api";
import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import useInfiniteScroll from "react-infinite-scroll-hook";
import styled from "styled-components";
import { ConversationContext, ConversationDetailsContext } from "./context";
import ConversationListApp from "./list/app";
import { conversationsListTestId, listLoadingIndicatorId } from "./testIds";
import { mergeConversationItems } from "@coxauto-ui/communications-util";
import { Alert } from "@interstate/components/Alert";
import { useInterstateTheme } from "@interstate/components/InterstateThemeProvider";

const StyledContentArea = styled.div`
  overflow-y: scroll;
  flex: 1;
  height: 100%;
  overflow-anchor: none;
  background-color: #f4f4f4;
`;
const StyledContainerAlert = styled.div`
  padding: 10px;
`;

// polling for new conversation items - can be removed when websockets are introduced
const getCommunicationsCallFrequencyMilliseconds = 15000;

export function ConversationPaneContent() {
  const { customerId, customerSystem, activeListTab, error, setError, env } =
    useContext(ConversationContext);
  const pollingTimer = useRef<number>(0);
  const isFirstLoad = useRef<boolean>(true);
  const {
    conversationItems,
    displayConversationItems,
    setConversationItems,
    setRecentContactEmail,
    setRecentContactText,
    setXSignature
  } = useContext(ConversationDetailsContext);
  const [loading, setLoading] = useState(false);
  const [nextPage, setNextPage] = useState<number | null | undefined>(1);
  const { tokens } = useInterstateTheme();

  const getConversationItems = useCallback(
    async (page?: number, isPolling = true) => {
      if (!isPolling) {
        setLoading(true);
      }
      const result = await getCommunications(
        {
          customerId,
          consumerRecordSystem: customerSystem,
          startingPoint: page || 1
        },
        env
      );
      if (isResponseError(result)) {
        if (!isPolling) {
          setError(getErrorMessage(result));
          setNextPage(null);
        }
      } else if (result.conversation !== null) {
        const mergeResult = mergeConversationItems(
          result.conversation,
          conversationItems
        );
        setXSignature(result.xSignature);

        if (page === undefined || page === 1) {
          setRecentContactEmail(result.recentContactEmail);
          setRecentContactText(result.recentContactText);
        }

        if (mergeResult.hasNewItems) {
          setConversationItems(mergeResult.items);
        }
        if (!isPolling) {
          const _nextItem = result?.nextItem;
          setNextPage(_nextItem);
        }
      }
      if (!isPolling) {
        setLoading(false);
      }
    },
    [
      customerId,
      customerSystem,
      setError,
      setRecentContactEmail,
      setRecentContactText,
      conversationItems,
      setConversationItems,
      env,
      setXSignature
    ]
  );

  useEffect(() => {
    if (isFirstLoad.current === true) {
      isFirstLoad.current = false;
    } else {
      if (pollingTimer.current) {
        clearInterval(pollingTimer.current);
      }

      setLoading(true);

      (async () => {
        const response = await getCommunications(
          {
            customerId,
            consumerRecordSystem: customerSystem,
            startingPoint: 1
          },
          env
        );

        if (isResponseError(response)) {
          setError(getErrorMessage(response));
        } else {
          setConversationItems(response.conversation);
          setRecentContactEmail(response.recentContactEmail);
          setRecentContactText(response.recentContactText);
          setXSignature(response.xSignature);
        }

        setLoading(false);
      })();
    }
  }, [
    customerId,
    setConversationItems,
    setError,
    setRecentContactEmail,
    setRecentContactText,
    env,
    setXSignature,
    customerSystem
  ]);

  useEffect(() => {
    pollingTimer.current = window.setInterval(async () => {
      await getConversationItems();
    }, getCommunicationsCallFrequencyMilliseconds);
    return () => {
      clearInterval(pollingTimer.current);
    };
  }, [getConversationItems]);

  // #region INFINITE SCROLL HANDLING
  // infinite scroll will eventually break on specific channel views
  // until the getCommunications endpoint handles requests by channel
  const [infiniteRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: !!nextPage,
    onLoadMore: async () => {
      await getConversationItems(nextPage || 1, false);
    },
    disabled: !!error,
    rootMargin: "400px 0px 0px 0px"
  });

  const scrollableRootRef = useRef<HTMLDivElement | null>(null);
  const lastScrollDistanceToBottomRef = useRef<number>(0);

  // We keep the scroll position when new items are added etc.
  useEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    const lastScrollDistanceToBottom =
      lastScrollDistanceToBottomRef.current ?? 0;
    if (scrollableRoot) {
      scrollableRoot.scrollTop =
        scrollableRoot.scrollHeight - lastScrollDistanceToBottom;
    }
  }, [displayConversationItems, rootRef]);

  useEffect(() => {
    const scrollableRoot = scrollableRootRef.current;
    if (scrollableRoot) {
      scrollableRoot.scrollTop = scrollableRoot.scrollHeight;
    }
  }, [activeListTab]);

  const rootRefSetter = useCallback(
    (node: HTMLDivElement) => {
      rootRef(node);
      scrollableRootRef.current = node;
    },
    [rootRef]
  );

  const handleRootScroll = useCallback(() => {
    const rootNode = scrollableRootRef.current;

    if (rootNode) {
      const scrollDistanceToBottom = rootNode.scrollHeight - rootNode.scrollTop;
      lastScrollDistanceToBottomRef.current = scrollDistanceToBottom;
    }
  }, []);
  // #endregion INFINITE SCROLL HANDLING

  return (
    <StyledContentArea
      data-testid={conversationsListTestId}
      ref={rootRefSetter}
      onScroll={handleRootScroll}
    >
      {(!loading && !error && displayConversationItems?.length === 0 && (
        <StyledContainerAlert>
          <Alert role="info" type="info">
            <span>No messages found</span>
          </Alert>
        </StyledContainerAlert>
      )) || <ConversationListApp />}
      {error && <span>{error}</span>}
      {(loading || nextPage) && (
        <span ref={infiniteRef}>
          <LoadingIndicator
            htmlId={listLoadingIndicatorId}
            text="Loading Conversation Message List"
          />
        </span>
      )}
    </StyledContentArea>
  );
}

export default ConversationPaneContent;
