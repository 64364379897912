import {
  ConversationItem,
  ConversationTypeEnum
} from "@coxauto-ui/communications-interfaces";
import styled from "styled-components";
import { ChannelTypeIcon } from "../core/channel-type-icon";
import { messageItemSenderAgeId, messageItemSenderNameId } from "../testIds";
import {
  deriveFormattedPhoneNumber,
  get12hrsTime
} from "@coxauto-ui/communications-util";

export const StyledConversationItemType = styled.span`
  vertical-align: middle;
  padding: 0.25em;
`;

export const StyledConversationItemName = styled.span`
  font-weight: 500;
  padding: 0.25em;
  padding-left: 0em;
`;

export const StyledConversationItemAge = styled.span`
  padding: 0.25em;
  padding-left: 0em;
  color: #5a5a5a;
  font-size: 0.8em;
`;

export interface ConversationListItemProps {
  conversationItem: ConversationItem;
}

export function MessageItemHeader({
  conversationItem
}: ConversationListItemProps) {
  return (
    <div>
      <StyledConversationItemType>
        <ChannelTypeIcon
          conversationType={conversationItem.type}
          eventResult={conversationItem.eventResult}
        />
      </StyledConversationItemType>
      <StyledConversationItemName
        data-testid={messageItemSenderNameId + conversationItem.key}
        id={messageItemSenderNameId + conversationItem.key}
      >
        {conversationItem.senderName} -
      </StyledConversationItemName>
      <StyledConversationItemAge
        data-testid={messageItemSenderAgeId + conversationItem.key}
        id={messageItemSenderAgeId + conversationItem.key}
      >
        {conversationItem.type === ConversationTypeEnum.TEXT &&
          deriveFormattedPhoneNumber(conversationItem.phoneNumber)}{" "}
        {get12hrsTime(new Date(conversationItem.date))}
      </StyledConversationItemAge>
    </div>
  );
}

export default MessageItemHeader;
