import {
  ContactDetail,
  ContactDetails,
  ConversationTypeEnum
} from "@coxauto-ui/communications-interfaces";
import { SelectInputOption } from "@interstate/components/SelectInput/Types/selectInputTypes";

export const getToChannelValueSelectInputOptions = (
  contactDetails: ContactDetails | null,
  currentConversationType: ConversationTypeEnum
): SelectInputOption[] => {
  if (
    !contactDetails ||
    currentConversationType === ConversationTypeEnum.NONE
  ) {
    return [];
  }

  const currentChannel =
    contactDetails.contactChannels[
      currentConversationType || ConversationTypeEnum.NONE
    ];

  // preserving this logic, but I'm not sure in what context we don't have a selected tab.
  if (!currentChannel || currentChannel.length === 0) {
    return [];
  }

  const currentChannelWithValues = currentChannel.filter(x => x.value);

  // don't map a list of options if there are no phone number values. Seems like something we'd want to handle in the bff
  if (currentChannelWithValues.length === 0) {
    return [];
  }

  return currentChannelWithValues.map(
    ({ consent, formattedValue }: ContactDetail) => {
      // display the opt out message next to the phone number when canSend is false
      const label = consent.canSend
        ? formattedValue
        : `${formattedValue} ${consent.status}`;
      return {
        disabled: !consent.canSend,
        label,
        value: formattedValue
      };
    }
  );
};
