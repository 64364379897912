/**
 * Formats a given phone number string into a standard (U.S.) format.
 *
 * The function:
 * - Removes any non-numeric characters from the input.
 * - Supports phone numbers with or without a country code (1).
 * - Returns the formatted number as "(XXX) XXX-XXXX" for U.S. numbers, or "+1 (XXX) XXX-XXXX" if a country code is included.
 *
 * Note:
 * - If the input is not a valid 10- or 11-digit phone number, the original input string is returned unchanged.
 *
 * @param phoneNumber - The phone number string to format.
 * @returns A formatted phone number string or the original input if it is not a valid phone number.
 */

export function deriveFormattedPhoneNumber(
  phoneNumber: string | null | undefined
): string {
  if (!phoneNumber) {
    return "";
  }
  const cleaned = phoneNumber.replace(/\D/g, "");
  const match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return `${intlCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }
  return phoneNumber;
}
