import styled from "styled-components";
import {
  BaseMatrixFilters,
  MatrixFilter,
  MatrixFilters,
  OptionFilters,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import { CcxmReusableBasicTabs } from "@coxauto-ui/ccxm/reusable/basic-tabs";
import { CcxmReusableCollapsible } from "@coxauto-ui/ccxm/reusable/collapsible";

export interface CcxmInsightsFiltersTabsFiltersProps {
  disabled: boolean;
  optionFilters: OptionFilters;
  matrixFilters: MatrixFilters;
  textFilters: TextFilters;
  categoryType: string;
  onChange: (
    optionFiltersToApply: OptionFilters,
    textFiltersToApply: TextFilters,
    matrixFiltersToApply?: MatrixFilters
  ) => void;
}

const StyledTabsWrapper = styled.div`
  font-size: 16px;
  margin-bottom: 10px;
  h5 {
    line-height: 120%;
    margin-bottom: 10px;
  }
`;

const StyledInsightsFiltersTabsFilters = styled.div``;

const IS_ALL = "All";

export function CcxmInsightsFiltersTabsFilters({
  textFilters,
  optionFilters,
  matrixFilters,
  categoryType,
  disabled,
  onChange
}: CcxmInsightsFiltersTabsFiltersProps) {
  const matrix = matrixFilters[categoryType];
  const getTabFilter = (
    { options, selectedValues, category }: MatrixFilter,
    categoryType: string
  ) => {
    const mappedTaps = options.map(option => {
      const isActive = selectedValues.includes(option.value);
      return {
        label: option.label,
        id: option.value,
        active: isActive || (option.value === IS_ALL && !selectedValues.length)
      };
    });

    const handleActivate = (tabId: string | undefined | null) => {
      if (!tabId) return;
      const matrixOptions = matrixFilters[categoryType].options.map(option => {
        if (option.category === category) {
          return {
            ...option,
            selectedValues: tabId === IS_ALL ? [] : [tabId]
          };
        }
        return option;
      });
      const newMatrixFilters: MatrixFilters = {
        ...matrixFilters,
        [categoryType]: {
          ...matrixFilters[categoryType],
          options: matrixOptions
        }
      };
      onChange(optionFilters, textFilters, newMatrixFilters);
    };

    return (
      <StyledTabsWrapper key={`${categoryType}-${category}`}>
        <h5>{category}</h5>
        <CcxmReusableBasicTabs
          disabled={disabled}
          htmlId={`${categoryType}-${category.toLowerCase()}`}
          tabs={mappedTaps}
          onActivate={(_, tab) => handleActivate(tab.id)}
        />
      </StyledTabsWrapper>
    );
  };

  const getTabFilters = (
    { displayName, options }: BaseMatrixFilters,
    categoryType: string
  ) => {
    return (
      <CcxmReusableCollapsible label={displayName}>
        {options.map(tabs => getTabFilter(tabs, categoryType))}
      </CcxmReusableCollapsible>
    );
  };

  return (
    matrix && (
      <StyledInsightsFiltersTabsFilters
        data-testid={`ccxm-insights-filters-tabs-filters-${categoryType}`}
      >
        {getTabFilters(matrix, categoryType)}
      </StyledInsightsFiltersTabsFilters>
    )
  );
}

export default CcxmInsightsFiltersTabsFilters;
