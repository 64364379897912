import { DealerCommunicationDetails } from "@coxauto-ui/communications-interfaces";
import { SelectInputOption } from "@interstate/components/SelectInput/Types/selectInputTypes";
import { deriveFormattedPhoneNumber } from "./derive-formatted-phone-number";

const formatLabel = (phoneNumber: string, name: string) => {
  const formattedPhoneNumber = deriveFormattedPhoneNumber(phoneNumber);
  return `${formattedPhoneNumber} - ${name}`;
};

export const getFromChannelValueSelectInputOptions = (
  dealerCommunicationDetails: DealerCommunicationDetails | null
): SelectInputOption[] => {
  if (!dealerCommunicationDetails) {
    return [];
  }

  const options: SelectInputOption[] = [];

  if (
    dealerCommunicationDetails.dealerPhoneNumber.phoneNumber &&
    dealerCommunicationDetails.dealerPhoneNumber.phoneNumber !== ""
  ) {
    const dealerPhoneNumberOption: SelectInputOption = {
      label: formatLabel(
        dealerCommunicationDetails.dealerPhoneNumber.phoneNumber,
        "Dealer"
      ),
      value: dealerCommunicationDetails.dealerPhoneNumber.phoneNumber
    };

    options.push(dealerPhoneNumberOption);
  }

  if (dealerCommunicationDetails.userPhoneNumbers.length > 0) {
    dealerCommunicationDetails.userPhoneNumbers.forEach(({ phoneNumber }) => {
      if (phoneNumber && phoneNumber !== "") {
        const userPhoneNumberOption: SelectInputOption = {
          label: formatLabel(phoneNumber, "My Phone"),
          value: phoneNumber
        };

        options.push(userPhoneNumberOption);
      }
    });
  }

  return options;
};
