import styled from "styled-components";
import { useMemo, useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@interstate/components/Icons";

export interface CcxmReusableCollapsibleProps {
  expandedByDefault?: boolean;
  label: string;
  htmlId?: string;
  children: React.ReactNode;
}

export const StyledCollapsibleTitle = styled.div`
  display: flex;
  justify-content: space-between;

  svg {
    margin-top: -3px;
  }
`;

export const StyledLabel = styled.h4`
  opacity: 1;
  color: #000;
  margin: 0 0 16px;
`;

export const StyledDivider = styled.hr`
  margin: 16px 0 16px;
`;

export function CcxmReusableCollapsible({
  expandedByDefault = true,
  label,
  children,
  htmlId = "ccxm-reusable-collapsible"
}: CcxmReusableCollapsibleProps) {
  const [expanded, setExpanded] = useState(expandedByDefault);

  const toggleExpandedRow = () => {
    setExpanded(b => !b);
  };

  const normalizedLabel = useMemo(() => {
    return label.toLowerCase().replace(" ", "-");
  }, [label]);

  return (
    <div
      className="ccxm-reusable-collapsible-container"
      data-testid={htmlId}
      id={htmlId}
    >
      <StyledDivider data-testid={`${htmlId}-divider`} />
      <StyledCollapsibleTitle onClick={toggleExpandedRow}>
        <StyledLabel data-testid={`${htmlId}-label`} id={`${htmlId}-label`}>
          {label}
        </StyledLabel>
        {expanded ? (
          <div id={`${htmlId}-up-arrow-${normalizedLabel}`}>
            <ChevronUpIcon
              data-testid={`${htmlId}-up-arrow-${normalizedLabel}`}
            />
          </div>
        ) : (
          <div id={`${htmlId}-down-arrow-${normalizedLabel}`}>
            <ChevronDownIcon
              data-testid={`${htmlId}-down-arrow-${normalizedLabel}`}
            />
          </div>
        )}
      </StyledCollapsibleTitle>
      <div>{expanded && children}</div>
    </div>
  );
}

export default CcxmReusableCollapsible;
