import { LoadingIndicator } from "@interstate/components/LoadingIndicator";
import {
  dealUpdatesRecordsActions,
  fetchDealInsightsRecords,
  getDealInsightsRecordsState,
  getDealUpdatesRecordsState,
  useAppDispatch
} from "@coxauto-ui/ccxm/store";
import { shallowEqual, useSelector } from "react-redux";
import { Button } from "@interstate/components/Button";
import { MegaphoneIcon } from "@interstate/components/Icons/MegaphoneIcon";
import { Popover } from "@interstate/components/Popover";
import { Typography } from "@interstate/components/Typography";
import { formatISO } from "date-fns";
import { useEffect, useMemo, useRef } from "react";
import { RealTimeNotificationCategory } from "@coxauto-ui/ccxm/interfaces";
import styled from "styled-components";
import { useSignalRMessage } from "@coxauto-ui/core/real-time-signalr";
import { getRealTimeNotificationType } from "@coxauto-ui/ccxm/util/get-real-time-notification-type";
import { Alert } from "@interstate/components/Alert";

const StyledMessageAction = styled(Button)`
  &&,
  &&:hover {
    text-decoration: underline;
    padding: 0;
    line-height: inherit;
    background-color: white;
    height: auto;
    margin: 0;
    font-size: 14px;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`;

export interface CcxmInsightsDealUpdatesProps {
  cleanTimeout?: number;
  handleGoogleAnalyticsEvent: (
    consumerId: null,
    eventName: string,
    recordRow: null
  ) => void;
  maxCount?: number;
}

export function CcxmInsightsDealUpdates({
  cleanTimeout = 0,
  handleGoogleAnalyticsEvent,
  maxCount = 99
}: CcxmInsightsDealUpdatesProps) {
  const { connected, message, isLoading } = useSignalRMessage({
    type: "broadcastMessage"
  });
  const ref = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const { optionFilters, paging, textFilters, loadingStatus, matrixFilters } =
    useSelector(getDealInsightsRecordsState, shallowEqual);

  const { dealUpdates, showDealUpdates } = useSelector(
    getDealUpdatesRecordsState,
    shallowEqual
  );

  const hasOptionFilters = useMemo(
    () =>
      Object.values(optionFilters).flatMap(o => o.selectedValues).length > 0,
    [optionFilters]
  );

  const hasTextFilters = useMemo(
    () =>
      Object.values(textFilters).flatMap(t =>
        t.textInputValue ? [t.textInputValue] : []
      ).length > 0,
    [textFilters]
  );

  const hasMatrixFilters = useMemo(
    () =>
      matrixFilters &&
      Object.values(matrixFilters).flatMap(m =>
        m.options.flatMap(o => o.selectedValues)
      ).length > 0,
    [matrixFilters]
  );

  const hasFilters = hasOptionFilters || hasTextFilters || hasMatrixFilters;
  const hasMoreUpdatesThanMaxCount = dealUpdates.length > maxCount;

  useEffect(() => {
    if (message && connected) {
      const notification = getRealTimeNotificationType(message.data);
      if (
        notification &&
        notification.notificationCategory ===
          RealTimeNotificationCategory.variable
      ) {
        dispatch(dealUpdatesRecordsActions.addDealUpdate(notification.id));
      }
    }
  }, [message, connected]);

  const getDealUpdates = async () => {
    handleGoogleAnalyticsEvent(null, "Updates Accepted", null);
    window.scrollTo(0, 0);
    ref.current?.click();
    await dispatch(
      fetchDealInsightsRecords({
        currentClientDateTime: formatISO(new Date()),
        optionFilters,
        matrixFilters,
        page: 1,
        pageSize: paging.pageSize,
        textFilters
      })
    );
    dispatch(dealUpdatesRecordsActions.showDealUpdates());
    setTimeout(() => {
      dispatch(dealUpdatesRecordsActions.clearDealUpdates());
    }, cleanTimeout);
  };

  const popoverContentText = (linkTxt: string | undefined, postfix: string) => {
    return (
      <Typography data-testid={"popover-content"} tag="span" variant="body-sm">
        {linkTxt && (
          <StyledMessageAction
            buttonStyle="tertiary"
            data-testid="new-updates"
            onClick={getDealUpdates}
          >
            {dealUpdates.length} {linkTxt}
          </StyledMessageAction>
        )}
        {postfix}
      </Typography>
    );
  };

  const popoverContentOptions = [
    {
      condition: dealUpdates.length === 0 || showDealUpdates,
      content: popoverContentText(
        undefined,
        "There are no new updates available at this time."
      )
    },
    {
      condition: dealUpdates.length === 1 && hasFilters && !showDealUpdates,
      content: popoverContentText(
        "New Update",
        ". Accepting it may or may not impact your filter view."
      )
    },
    {
      condition: dealUpdates.length > 1 && hasFilters && !showDealUpdates,
      content: popoverContentText(
        "New Updates",
        ". Accepting them may or may not impact your filter view."
      )
    },
    {
      condition: dealUpdates.length === 1 && !hasFilters && !showDealUpdates,
      content: popoverContentText(
        "New Update",
        ". Please accept to view your update."
      )
    },
    {
      condition: dealUpdates.length > 1 && !hasFilters && !showDealUpdates,
      content: popoverContentText(
        "New Updates",
        ". Please accept to view your updates."
      )
    }
  ];

  const popoverContent = useMemo(() => {
    return (
      popoverContentOptions.find(item => item.condition)?.content ||
      popoverContentText(
        undefined,
        "There are no new updates available at this time."
      )
    );
  }, [dealUpdates.length, hasFilters, showDealUpdates]);

  if (isLoading || (loadingStatus === "loading" && dealUpdates.length > 0)) {
    return <LoadingIndicator data-testid={"loading-indicator-deal-updates"} />;
  }

  const megaphoneIcon = <MegaphoneIcon fontSize="14px" />;
  const dealUpdatesCount =
    dealUpdates.length > 0 && !showDealUpdates ? (
      <span data-testid={"deal-updates-count"}>
        {hasMoreUpdatesThanMaxCount
          ? ` (${maxCount}+)`
          : ` (${dealUpdates.length})`}
      </span>
    ) : null;

  return (
    <StyledContainer>
      <div ref={ref}>
        <Popover
          data-testid={"deal-updates-popover"}
          popoverContent={popoverContent}
          position="bottom"
          trigger="outsideClick"
        >
          <Button
            buttonStyle="tertiary"
            data-testid={"deal-updates-button"}
            disabled={!connected}
            size="small"
            startIcon={megaphoneIcon}
          >
            <span>Deal Updates{dealUpdatesCount}</span>
          </Button>
        </Popover>
      </div>
      {!connected ? (
        <Alert
          data-testid="deal-real-time-updates-alert"
          role="alert"
          type="warning"
        >
          Deal update notifications have been suspended by the browser. Please{" "}
          <StyledMessageAction
            buttonStyle="tertiary"
            data-testid="reload-action"
            onClick={() => {
              window.location.reload();
            }}
          >
            refresh
          </StyledMessageAction>{" "}
          to reestablish your connection.
        </Alert>
      ) : null}
      <div />
    </StyledContainer>
  );
}

export default CcxmInsightsDealUpdates;
