import { CcxmInsightsList } from "@coxauto-ui/ccxm/insights/list";
import {
  DealInsightsRecordsEntity,
  FilterSequence,
  FilterSequenceFilterType,
  LoadingStatus,
  MatrixFilters,
  OptionFilters,
  Paging,
  TextFilterComponentType,
  TextFilters
} from "@coxauto-ui/ccxm/interfaces";
import {
  fetchDealInsightsRecords,
  getDealInsightsRecordsState,
  getUserProfileState,
  selectAllDealInsightsRecords,
  selectDealUpdatesToShow
} from "@coxauto-ui/ccxm/store";
import { shallowEqual, useSelector } from "react-redux";
import { useAppDispatch } from "@coxauto-ui/ccxm/store";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { getMultiDealInsights } from "@coxauto-ui/ccxm/api";
import { formatISO } from "date-fns";
import { Card, StyledCardWrapper } from "@coxauto-ui/ccxm/close-my-deals/card";
import useInsightsListMode from "@coxauto-ui/ccxm/util/use-insights-list-mode";
import { TagManager } from "@coxauto-ui/core-third-party-ga4";
import deriveCustomerViewLink from "@coxauto-ui/util/derive-customer-view-link";
import deriveDealListActionContext from "@coxauto-ui/util/derive-deal-list-action-context";
import getSelectedFilterCategories from "@coxauto-ui/util/get-selected-filter-categories";
import {
  deriveEnvironmentName,
  environments
} from "@coxauto-ui/ccxm/util/derive-environment";
import {
  RoutingContext,
  SetSearchParamsProps,
  useRouting,
  useRoutingContext,
  useRoutingLocation,
  useRoutingParams,
  useRoutingSearchParams
} from "@coxauto-ui/ccxm-routing";
import { Alert } from "@interstate/components/Alert";
import { CcxmInsightsDealUpdates } from "@coxauto-ui/ccxm/insights/deal-updates";
import { CustomerCardTabTypes } from "@coxauto-ui/customer-interfaces";
import { useCxmFlags } from "@coxauto-ui/core/third-party/launch-darkly";

export function CcxmCloseMyDealsList() {
  const navigate = useRouting();
  const dispatch = useAppDispatch();
  const { pathname, search } = useRoutingLocation();
  const params = useRoutingParams();
  const [searchParams, setSearchParams] = useRoutingSearchParams();

  const {
    selectedMultiDealCustomerId,
    setSelectedCustomerId,
    setSelectedDealId,
    setSelectedMultiDealCustomerId,
    setDefaultCustomerTab
  } = useContext(RoutingContext);

  const [cardsLoadingStatus, setCardsLoadingStatus] = useState<LoadingStatus>(
    LoadingStatus.notLoaded
  );
  const [cardsError, setCardsError] = useState("");
  const [multiDealRecords, setMultiDealRecords] = useState<
    DealInsightsRecordsEntity[]
  >([]);
  const listMode = useInsightsListMode();

  const { dealerGroupId, dealerGroupName, bridgeUserId, viewAsUsername } =
    useSelector(getUserProfileState, shallowEqual);
  const {
    error,
    filterSequence,
    loadingStatus,
    optionFilters,
    paging,
    matrixFilters,
    textFilters
  } = useSelector(getDealInsightsRecordsState, shallowEqual);
  const dealUpdates = useSelector(selectDealUpdatesToShow, shallowEqual);
  const dealRecords = useSelector(selectAllDealInsightsRecords, shallowEqual);
  const { isModule } = useRoutingContext();
  const {
    "nx.cxm.kill.crw-deallist-persona": persona,
    "nx.cxm.show-dealtrack": showDealTracker
  } = useCxmFlags();

  const hidePersona = useMemo(() => {
    return persona === undefined ? true : persona;
  }, [persona]);

  useEffect(() => {
    const savedFilters = localStorage.getItem(
      `filters_deals_${bridgeUserId}_${viewAsUsername}`
    );
    if (savedFilters) {
      const {
        optionFilters: savedOptionFilters,
        textFilters: savedTextFilters,
        matrixFilters: savedMatrixFilters
      } = JSON.parse(savedFilters);
      dispatch(
        fetchDealInsightsRecords({
          currentClientDateTime: formatISO(new Date()),
          optionFilters: savedOptionFilters,
          page: 1,
          pageSize: paging.pageSize,
          matrixFilters: savedMatrixFilters,
          textFilters: savedTextFilters
        })
      );
    }
  }, [
    bridgeUserId,
    dispatch,
    paging.pageSize,
    viewAsUsername,
    showDealTracker
  ]);

  useEffect(() => {
    const multiDealConsumerId = selectedMultiDealCustomerId;
    if (multiDealConsumerId === null) {
      setMultiDealRecords([]);
      setCardsError(error);
      setCardsLoadingStatus(loadingStatus);
    } else {
      setCardsLoadingStatus(LoadingStatus.loading);
      setCardsError("");

      // There is no error handling here...
      (async () => {
        const deals = await getMultiDealInsights(multiDealConsumerId);

        setMultiDealRecords(deals);
        setCardsLoadingStatus(LoadingStatus.loaded);
      })();
    }
  }, [error, loadingStatus, searchParams, selectedMultiDealCustomerId]);

  const analyticsFilterList = useMemo(() => {
    return getSelectedFilterCategories({
      filterSequence,
      optionFilters,
      textFilters,
      matrixFilters
    });
  }, [filterSequence, optionFilters, textFilters, matrixFilters]);

  const onFilterChange = useCallback(
    (
      optionFiltersToApply: OptionFilters,
      textFiltersToApply: TextFilters,
      matrixFiltersToApply?: MatrixFilters
    ) => {
      if (multiDealRecords.length > 0) {
        setSelectedCustomerId(null);
        setSelectedDealId(null);
        setSelectedMultiDealCustomerId(null);
        navigate("/list/deals", { replace: false });
      } else {
        dispatch(
          fetchDealInsightsRecords({
            currentClientDateTime: formatISO(new Date()),
            optionFilters: optionFiltersToApply,
            page: 1,
            matrixFilters: matrixFiltersToApply,
            pageSize: paging.pageSize,
            textFilters: textFiltersToApply
          })
        )
          .unwrap()
          .then(() => {
            localStorage.setItem(
              `filters_deals_${bridgeUserId}_${viewAsUsername}`,
              JSON.stringify({
                matrixFilters: matrixFiltersToApply,
                optionFilters: optionFiltersToApply,
                textFilters: textFiltersToApply
              })
            );
          })
          .catch(() => {
            // Do nothing
          });
      }
    },
    [
      multiDealRecords.length,
      setSelectedCustomerId,
      setSelectedDealId,
      setSelectedMultiDealCustomerId,
      navigate,
      dispatch,
      paging.pageSize,
      bridgeUserId,
      viewAsUsername
    ]
  );

  const onLoadMore = useCallback(() => {
    dispatch(
      fetchDealInsightsRecords({
        currentClientDateTime: formatISO(new Date()),
        optionFilters,
        matrixFilters,
        page: paging.pageNumber + 1,
        pageSize: paging.pageSize,
        textFilters
      })
    );
  }, [
    dispatch,
    optionFilters,
    matrixFilters,
    paging.pageNumber,
    paging.pageSize,
    textFilters
  ]);

  const handleSelectMultiDeal = useCallback(
    (consumerId: string, dealId: string) => {
      const newParams: SetSearchParamsProps = {
        multiDealConsumerId: consumerId,
        dealId
      };
      setSearchParams(newParams);
    },
    [setSearchParams]
  );

  const handleGoogleAnalyticsEvent = useCallback(
    (
      consumerId: string | null,
      eventName: string,
      recordRow: number | null
    ) => {
      const env = deriveEnvironmentName();
      const actionContext = deriveDealListActionContext(
        listMode,
        window.location.href
      );

      TagManager.event({
        event: "productEvent",
        eventName,
        eventProperties: {
          listContext: "Retail360",
          commonCustomerId: consumerId,
          errorMessage: undefined,
          filterCategories: analyticsFilterList,
          actionContext,
          listPlacement: recordRow,
          appName: isModule ? "Vin" : "CRW",
          isProduction: env === environments.prod,
          dealerGroupId
        }
      });
    },
    [analyticsFilterList, dealerGroupId, isModule, listMode]
  );

  const handleDealCardClick = useCallback(
    (
      consumerId: string,
      dealId: string,
      recordRow: number,
      defaultCustomerTab?: CustomerCardTabTypes
    ) => {
      handleGoogleAnalyticsEvent(consumerId, "Customer Opened", recordRow);
      setSelectedCustomerId(consumerId);
      setSelectedDealId(dealId);
      setDefaultCustomerTab(
        defaultCustomerTab || CustomerCardTabTypes.information
      );
      const dealCustomerViewLink = deriveCustomerViewLink(
        consumerId,
        dealId,
        params.customerId || "",
        pathname,
        search,
        defaultCustomerTab
      );
      navigate(dealCustomerViewLink);
    },
    [
      handleGoogleAnalyticsEvent,
      navigate,
      params,
      pathname,
      search,
      setDefaultCustomerTab,
      setSelectedCustomerId,
      setSelectedDealId
    ]
  );

  const listFilterSequence: FilterSequence[] = useMemo(() => {
    return multiDealRecords.length > 0
      ? [
          {
            categoryType: "multiDealConsumerName",
            filterType: FilterSequenceFilterType.textFilter
          }
        ]
      : filterSequence;
  }, [filterSequence, multiDealRecords.length]);

  const listTextFilters: TextFilters = useMemo(() => {
    return multiDealRecords.length > 0
      ? {
          multiDealConsumerName: {
            componentType: TextFilterComponentType.multiDeal,
            textInputValue: `Show All ${[
              multiDealRecords[0].firstName,
              multiDealRecords[0].lastName
            ].join(" ")}'s Deals (${multiDealRecords.length})`
          }
        }
      : textFilters;
  }, [multiDealRecords, textFilters]);

  const listPaging: Paging = useMemo(() => {
    return multiDealRecords.length > 0
      ? {
          pageCount: 1,
          pageNumber: 1,
          pageSize: multiDealRecords.length,
          totalItems: multiDealRecords.length
        }
      : paging;
  }, [multiDealRecords.length, paging]);

  const recordsToMap = useMemo(() => {
    return multiDealRecords.length > 0 ? multiDealRecords : dealRecords;
  }, [dealRecords, multiDealRecords]);

  const hasSelectedFilters = useMemo(() => {
    let hasSelectedFilter = false;
    for (let i = 0; i < filterSequence.length; i++) {
      if (
        filterSequence[i].filterType === FilterSequenceFilterType.optionFilter
      ) {
        if (
          optionFilters[filterSequence[i].categoryType].selectedValues.length >
          0
        ) {
          hasSelectedFilter = true;
          break;
        }
      } else if (
        filterSequence[i].filterType === FilterSequenceFilterType.textFilter
      ) {
        if (
          textFilters[filterSequence[i].categoryType].textInputValue.length > 0
        ) {
          hasSelectedFilter = true;
          break;
        }
      } else if (
        filterSequence[i].filterType === FilterSequenceFilterType.matrixFilter
      ) {
        if (
          matrixFilters &&
          matrixFilters[filterSequence[i].categoryType].options.some(
            option => option.selectedValues.length > 0
          )
        ) {
          hasSelectedFilter = true;
          break;
        }
      }
    }
    return hasSelectedFilter;
  }, [filterSequence, optionFilters, textFilters, matrixFilters]);

  const alertMessage = useMemo(() => {
    if (hasSelectedFilters) {
      return "Sorry, there are no results available. Please try a different selection.";
    }

    return "Sorry, there are no deals at this time. Please try again later.";
  }, [hasSelectedFilters]);

  return (
    <CcxmInsightsList
      dealerGroupId={dealerGroupId}
      dealerGroupName={dealerGroupName}
      error={cardsError}
      filterSequence={listFilterSequence}
      loading={cardsLoadingStatus === "loading"}
      matrixFilters={showDealTracker ? matrixFilters : undefined}
      optionFilters={optionFilters}
      pageTitle="Deals"
      paging={listPaging}
      showFilters={multiDealRecords.length === 0}
      showRealTimeUpdates={true}
      textFilters={listTextFilters}
      updateNotifications={
        <CcxmInsightsDealUpdates
          cleanTimeout={5000}
          handleGoogleAnalyticsEvent={handleGoogleAnalyticsEvent}
          maxCount={99}
        />
      }
      onFilterChange={onFilterChange}
      onLoadMore={onLoadMore}
    >
      <StyledCardWrapper>
        {recordsToMap.length === 0 &&
          loadingStatus === LoadingStatus.loaded && (
            <Alert
              data-testid="deal-list-no-results-alert"
              role={"alert"}
              type={"info"}
            >
              {alertMessage}
            </Alert>
          )}
        {recordsToMap.map(
          (
            {
              consumerId,
              dealId,
              dealStages,
              dealershipId,
              dealerName,
              employees,
              firstName,
              hasMultipleDeals,
              isAccepted,
              lastName,
              lastUpdatedDateUtc,
              manageDealButtonLink,
              mostRecentUpdateMessages,
              sellingVehicle,
              tradeVehicles,
              viewDealButtonLink,
              persona
            },
            index
          ) => {
            const isNewDeal = !!dealUpdates.find(id => id === dealId);
            return (
              <Card
                consumerFirstName={firstName}
                consumerId={consumerId}
                consumerLastName={lastName}
                dealId={dealId}
                dealStages={showDealTracker ? dealStages : undefined}
                dealershipId={dealershipId}
                dealershipName={dealerName}
                employees={employees}
                handleDealCardClick={handleDealCardClick}
                handleGoogleAnalyticsEvent={handleGoogleAnalyticsEvent}
                handleSelectMultiDeal={handleSelectMultiDeal}
                integratorLastUpdatedUtc={lastUpdatedDateUtc}
                isAccepted={isAccepted}
                isNewDeal={isNewDeal}
                key={dealId}
                listMode={listMode}
                manageDealButtonLink={manageDealButtonLink}
                mostRecentUpdateMessages={mostRecentUpdateMessages}
                persona={hidePersona ? "" : persona}
                recordRow={index + 1}
                sellingVehicle={sellingVehicle}
                showMultiDealButton={
                  multiDealRecords.length === 0 && hasMultipleDeals
                }
                tradeVehicles={tradeVehicles}
                viewDealButtonLink={viewDealButtonLink}
              />
            );
          }
        )}
      </StyledCardWrapper>
    </CcxmInsightsList>
  );
}

export default CcxmCloseMyDealsList;
